<template>
	<div class="container">
		<div class="model" v-if="editNameModal" @click.stop="cancelModal"></div>
		<div class="edit-wrap flex-column align-center" v-if="editNameModal">
			<div class="edit-title">修改店铺名称</div>
			<div class="edit-line"></div>
			<input type="text" maxlength="10" oninput="if(value.length > 10)value = value.slice(0, 10)" v-model="name"
				class="edit-input" placeholder="请输入店铺名称">
			<div class="edit-line"></div>
			<div class="edit-btn" @click="doEdit()">确认修改</div>
		</div>
		<div class="flex-row align-center justify-center nav">
			<div class="nav-title-bg"></div>
			<div class="nav-title">古玩店</div>
			<div class="flex-row align-center sc-wrap" @click.stop="navCollect()">
				<img src="../../assets/shop/icon-collect.png" class="icon-collect" alt="">
				<img src="../../assets/shop/collect-ti.png" class="collect-ti" alt="">
			</div>
		</div>
		<div class="gwd-check-wrap flex-row align-top " :class="list.length <3?'justify-center':''">
			<div class="flex-column  align-center" v-for="(item,index) in list" :key="index"
				@click.stop="checkShop(index)">
				<div class="gwd-check-item" :class="index == curIndex ?'gwd-check-item-sel':''">{{item.entryNo}}</div>
				<div class="mr-text" v-if="item.isDefault == 1">默认店铺</div>
			</div>
		</div>
		<div class="name-wrap flex-row align-center justify-center"
			v-show="list.length>0 && list[curIndex].linkshopid !=''">
			<div class="shop-name">{{shopName}}</div>
			<img src="../../assets/shop/edit-shop.png" @click.stop="showEditNameModal()" class="icon-edit" alt="">
		</div>
		<div class="shop-wrap flex-row align-center">
			<img src="../../assets/shop/gwd.png" class="gwd-img" alt="">
			<img src="../../assets/shop/gwd-sel.png" class="gwd-img" alt="">
			<img src="../../assets/shop/gwd.png" class="gwd-img" alt="">
		</div>
		<div class="btn-wrap flex-row align-center justify-center" v-show="list.length>0">
			<div class="btn-item flex-column align-center" @click.stop="share()">
				<img src="../../assets/shop/tab1.png" alt="" class="tab1">
				<img src="../../assets/shop/tab1-1.png" alt="" class="tab2">
			</div>
			<div class="btn-item2 flex-column align-center" @click.stop="navShop()">
				<img src="../../assets/shop/tab2.png" alt="" class="tab1">
				<img src="../../assets/shop/tab2-1.png" alt="" class="tab3">
			</div>
		</div>
	</div>
</template>

<script>
	import wx from 'weixin-js-sdk'
	import config from '../../config/config.js';
	import util from '../../config/util.js';
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	export default {
		data() {
			return {
				list: [],
				curIndex: 0,
				shopName: '',
				editNameModal: 0,
				name: ''
			}
		},
		methods: {
			doEdit() {
				const that = this;
				if (that.name == '') {
					Toast('请输入昵称');
					return;
				}
				let name = that.name
				for (let i = 0; i < 10; i++) {
					name = name.replace(/[\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/, '')
				}
				let params = new FormData()
				params.append('newShopName', name);
				params.append('shopId', that.list[that.curIndex].linkShopId);
				this.axios.post(config.requestUrl + '/front/antiqueShop/updateNameByShopId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						Toast('修改成功');
						that.editNameModal = 0;
						this.shopName = name;
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);

					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			showEditNameModal() {
				this.name = ''
				this.editNameModal = 1;
			},
			cancelModal() {
				this.editNameModal = 0;
			},
			navCollect() {
				this.$router.push('/shop/collect')
			},
			checkShop(index) {
				console.log(index)
				// this.curIndex = index
			},
			getAntiqueShopList() {
				const that = this
				let params = new FormData()
				that.axios.post(config.requestUrl + '/front/antiqueShop/getAntiqueShopList', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.list = response.data.obj
						if (response.data.obj && response.data.obj.length > 0) {
							if (response.data.obj) {
								for (let i in response.data.obj) {
									if (response.data.obj[i].isDefault == 1) {
										that.curIndex = i;
										that.shopName = response.data.obj[i].linkShopName
										break;
									}
								}
							}
						} else {
							Dialog({
								title: '请先购置古玩店，才可以开店。'
							})
						}
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(function() {
								that.$router.push('/')
							}, 1200);
						}
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			createAntiqueShopCard() {
				const that = this
				let params = new FormData()
				that.axios.post(config.requestUrl + '/front/antiqueShop/createAntiqueShopCard', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					this.getAntiqueShopList();
					console.log(response)
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			navShop() {
				const that = this
				let params = new FormData()
				that.axios.post(config.requestUrl + '/front/antiqueShop/createAntiqueShopCard', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					window.location.href = config.tdUrl + '/?id=' + response.data.obj.id
					console.log(response)
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			share() {
				this.$router.push('/shop/share')

			}
		},
		mounted() {

			this.createAntiqueShopCard();
		}
	}
</script>

<style scoped="scoped">
	.container {
		background: url('../../assets/shop/bg.jpg');
		background-size: 750px 1448px;
		background-repeat: repeat;
		min-height: 100%;
	}

	.gwd-check-wrap {
		margin-top: 60px;
		width: 100%;
		padding: 0 40px;
		box-sizing: border-box;
		overflow-x: scroll;
		flex-wrap: nowrap;
	}

	.gwd-check-item {
		width: 250px;
		height: 115px;
		text-align: center;
		line-height: 105px;
		font-size: 28px;
		font-weight: 400;
		color: #AF9C8B;
		flex-shrink: 0;
	}

	.gwd-check-item-sel {
		width: 250px;
		height: 115px;
		background-image: url(../../assets/shop/btn-bg.png);
		background-size: 250px 115px;
		border-radius: 41px;
		font-size: 28px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
	}

	.mr-text {
		margin-top: 6px;
		font-size: 26px;
		font-weight: 400;
		color: #FFFFFF;
	}

	.shop-wrap {
		width: 100%;
		overflow: hidden;
		margin-top: 40px;
	}

	.gwd-img {
		width: 479px;
		height: 780px;
		margin: 0 30px;
	}

	.gwd-img:first-child {
		margin-left: -400px;
	}

	.gwd-img:last-child {
		margin-right: -400px;
	}

	.tab1 {
		width: 168px;
		height: 168px;
	}

	.tab2 {
		width: 179px;
		height: 30px;
	}

	.tab3 {
		width: 147px;
		height: 31px;
	}

	.btn-item2 {
		margin-left: 47px;
	}

	.sc-wrap {
		position: absolute;
		top: 14px;
		right: 0;
		width: 168px;
		height: 54px;
		background: #6236FF;
		border-radius: 100px 0px 0px 100px;
	}

	.icon-collect {
		width: 53px;
		height: 53px;
		margin-left: 8px;
	}

	.collect-ti {
		width: 86px;
		height: 22px;
	}

	.btn-wrap {
		padding-bottom: 100px;
	}

	.name-wrap {
		margin-top: 30px;
	}

	.shop-name {
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
	}

	.icon-edit {
		width: 23px;
		height: 23px;
		margin-left: 10px;
	}


	.edit-wrap {
		width: 630px;
		height: max-content;
		background: #FFFFFF;
		border-radius: 20px;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1001;
		margin: auto;
	}

	.edit-title {
		font-size: 30px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
		margin: 30px 0;
	}

	.edit-line {
		width: 100%;
		height: 2px;
		background: rgba(0, 0, 0, 0.1);
	}

	.edit-input {
		width: 480px;
		height: 70px;
		background: rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		padding: 17px 20px;
		box-sizing: border-box;
		margin: 35px 0 39px;
		border: none;
	}

	.edit-btn {
		margin-top: 20px;
		padding-bottom: 30px;
		font-size: 30px;
		font-weight: 400;
		color: #000000;
	}

	.edit-msg {
		width: 470px;
		margin: 40px 0;
		font-size: 26px;
		font-weight: 400;
		color: #000000;
	}
</style>
